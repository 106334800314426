import React, { lazy, Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "@fontsource/poppins";
import Header from "../LoPages/Header/header";
import Banner from "./Banner/banner";
import axios from "axios";

const Features = lazy(() => import("../LoPages/Features/features.js"));
const Renovation = lazy(() => import("../LoPages/Renovation/renovation.js"));
const Costs = lazy(() => import("../LoPages/Costs/costs.js"));
const Lodetails = lazy(() => import("../LoPages/Lodetails/lodetails.js"));
const BorrowerLeadForm = lazy(() => import("../LoPages/Contact/BorrowerLeadForm.js"));
const Footer = lazy(() => import("../LoPages/Footer/footer.js"));

function Lopage() {
    const { loName } = useParams();
    const loJsonName = loName.toLowerCase() + ".json";

    const [loading, setLoading] = useState(true);
    const [loanOfficerData, setLoanOfficerData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchData = async () => {
        try {
            const loResponse = await axios.get(
                `${process.env.REACT_APP_CDN}swmc/jsons/loanOfficer/loanOfficerJson/${loJsonName}`
            );

            console.log("LO Response: ", loResponse.data); // Log the entire response to check structure

            // Ensure the structure is valid before accessing properties
            if (loResponse.data && loResponse.data.loanOfficer) {
                setLoanOfficerData(loResponse.data.loanOfficer);
                console.log("Loan officer data from response:", loResponse.data.loanOfficer);
            } else {
                console.error("Loan officer information is missing in the response");
                setErrorMessage("Loan officer information is missing in the response.");
            }

        } catch (error) {
            const errorMsg = error.response
                ? `Error: ${error.response.status} - ${error.response.data}`
                : error.message;

            console.error('Error fetching data from CDN:', errorMsg);
            setErrorMessage("Error fetching loan officer data. Please check your network connection.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [loJsonName]);

    return (
        <React.Fragment>
            <div className="bodyContainer">
                {loading ? (
                    <div className="mt-5 mb-3 text-center">
                        <Spinner animation="border" />
                        <h5>Loading...</h5>
                    </div>
                ) : (
                    <>
                        {errorMessage ? (
                            <div className="mt-5 mb-3 text-center">
                                <h5>{errorMessage}</h5>
                            </div>
                        ) : (
                            loanOfficerData && (
                                <Suspense fallback={<div />}>
                                    <Header loanOfficerData={loanOfficerData} />
                                    <Banner loanOfficerData={loanOfficerData} />
                                    <Features loanOfficerData={loanOfficerData} />
                                    <Renovation loanOfficerData={loanOfficerData} />
                                    <Costs loanOfficerData={loanOfficerData} />
                                    <Lodetails loanOfficerData={loanOfficerData} />
                                    <BorrowerLeadForm loanOfficerData={loanOfficerData} />
                                    <Footer />
                                </Suspense>
                            )
                        )}
                    </>
                )}
            </div>
        </React.Fragment>
    );
}

export default Lopage;
